import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styles from "./Header.scss";
import { ReactComponent as Hamburger } from "../icons/Hamburger.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
export function MobileNav({ isAdmin }) {
  const [navOpen, setNav] = useState(false);
  const toggleNav = () => {
    setNav(!navOpen);
  };
  const cloud = "cloud";

  return (
    <>
      <div className={styles.navContainer}>
        <div className={styles.mobileNavWrapper}>
          <Hamburger onClick={toggleNav} />
          <header className={`${navOpen ? `is-active ${cloud}` : "hide"}`}>
            <nav role="navigation">
              <ul>
                {isAdmin && (
                  <li>
                    <a style={{ marginLeft: 0 }} href="/admin" rel="noreferrer noopener">
                      <i>
                        <FontAwesomeIcon icon={faCog} />
                      </i>
                      &nbsp;
                      <FormattedMessage id="header.admin" defaultMessage="Admin" />
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </header>
        </div>
      </div>
    </>
  );
}

MobileNav.propTypes = {
  appName: PropTypes.string,
  appLogo: PropTypes.string,
  isAdmin: PropTypes.bool,
  isSignedIn: PropTypes.bool,
  onSignOut: PropTypes.func
};
