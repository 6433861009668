import React from "react";
import PropTypes from "prop-types";
import styles from "./Footer.scss";
import { Container } from "./Container";

export function Footer() {
  return (
    <footer>
      <Container as="div" className={styles.container}>
        <nav>
          <ul>
            <li>
              <a className={styles.link} href="https://www.tomatopass.com/main.do">
                {"토마토 패스"}
              </a>
            </li>
            <li>
              <a className={styles.link} href="/terms-of-use">
                {"이용약관"}
              </a>
            </li>
            <li>
              <a className={styles.link} href="/privacy-policy">
                {"개인정보보호정책"}
              </a>
            </li>
          </ul>
        </nav>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  hidePoweredBy: PropTypes.bool,
  showCompanyLogo: PropTypes.bool,
  companyLogoUrl: PropTypes.string
};
