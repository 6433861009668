import React from "react";
import PropTypes from "prop-types";
import "./Page.scss";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { MobileNav } from "./MobileNav";

export function Page({
  appLogo,
  isAdmin,
  isSignedIn,
  onSignOut,
  hidePoweredBy,
  showCompanyLogo,
  companyLogoUrl,
  appName,
  children,
  ...rest
}) {
  return (
    <>
      <Header appName={appName} appLogo={appLogo} isAdmin={isAdmin} isSignedIn={isSignedIn} onSignOut={onSignOut} />
      <main {...rest}>
        <MobileNav isAdmin={isAdmin} />
        {children}
      </main>
      <Footer
        hidePoweredBy={hidePoweredBy}
        showCompanyLogo={showCompanyLogo}
        companyLogoUrl={companyLogoUrl}
        appName={appName}
      />
    </>
  );
}

Page.propTypes = {
  appLogo: PropTypes.string,
  isAdmin: PropTypes.bool,
  isSignedIn: PropTypes.bool,
  onSignOut: PropTypes.func,
  hidePoweredBy: PropTypes.bool,
  showCompanyLogo: PropTypes.bool,
  companyLogoUrl: PropTypes.string,
  appName: PropTypes.string,
  children: PropTypes.node
};
