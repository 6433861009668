// These are set in the admin panel and are only included as fallbacks.
const defaultLocaleData = {
  "app-name": "App",
  "editor-name": "Scene Editor",
  "contact-email": "app@company.com",
  "company-name": "Company",
  "share-hashtag": "#app",
  "app-description": "Gather share and collaborate together in a virtual, private and safe space",
  "app-tagline": "Private social VR in your web browser"
};

const _locale = "ko";
const _localeData = defaultLocaleData;

export const getLocale = () => {
  return _locale;
};

export const getMessage = key => {
  return _localeData[key];
};
