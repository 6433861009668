import uuid from "uuid/v4";

export default class AuthChannel {
  constructor(store) {
    this.store = store;
    this.socket = null;
    this._signedIn = !!this.store.state.credentials.token;
  }

  setSocket = socket => {
    this.socket = socket;
  };

  get signedIn() {
    return this._signedIn;
  }

  signIn(phone, password, hubChannel) {
    const channel = this.socket.channel(`auth:${uuid()}`);
    return new Promise((resolve, reject) => {
      channel.onError(() => {
        channel.leave();
        reject();
      });

      channel
        .join()
        .receive("ok", () => {
          channel.on("auth_credentials", async ({ credentials: token }) => {
            await this.handleAuthCredentials(token, hubChannel);
            resolve();
          });

          channel.push("auth_request", { phone, password, nation: "KR", device_token: "", device_type: "web" });
        })
        .receive("error", reject);
    });
  }

  signOut = async hubChannel => {
    if (hubChannel) {
      await hubChannel.signOut();
    }
    this.store.update({ credentials: { token: null } });
    await this.store.resetToRandomDefaultAvatar();
    this._signedIn = false;
  };

  async handleAuthCredentials(token, hubChannel) {
    this.store.update({ credentials: { token } });

    if (hubChannel) {
      await hubChannel.signIn(token);
    }

    this._signedIn = true;
  }
}
