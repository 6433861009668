import React from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import koMessages from "../assets/locales/ko.json";

export class WrappedIntlProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  render() {
    return (
      <IntlProvider locale={"ko"} messages={koMessages}>
        {this.props.children}
      </IntlProvider>
    );
  }
}
